import * as React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/Layout"
import TxtImg from "../components/TxtImg/TxtImg"
import styled from "styled-components"
import Button from "../components/Button/Button"
import { projects } from "../util/projects"
import { Helmet } from "react-helmet"

const ProjectContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-4) var(--space-6);
    margin: var(--space-8) 0;
    align-items: center;

    &:first-child {
        margin-top: var(--space-2);
    }

    &:last-child {
        margin-bottom: var(--space-2);
    }
`

const ProjectImage = styled.div`
    flex-basis: 400px;
    flex-grow: 1;
`

const ProjectText = styled.div`
    flex-basis: 400px;
    flex-grow: 1;

    h3 {
        margin-top: 0;
        font-size: var(--type-m);
    }
`

const Divider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }

    .shape-fill {
        fill: #1F3236;
    }
`

const Project = props => (
    <ProjectContainer>
        <ProjectImage>
            <GatsbyImage image={props.image} alt={props.alt} />
        </ProjectImage>
        <ProjectText>
            <h3>{props.name}</h3>
            <p>{props.intro1}</p>
            <p>{props.intro2}</p>
            <Button externalLink={true} linkTo={props.href}>View live project</Button>
        </ProjectText>
    </ProjectContainer>
)

function Projects({ data }) {
    const projectImage = getImage(data.projectImage)
    const growing = getImage(data.growing)

    return (
        <Layout pageTitle="Projects">
            <Helmet>
                <meta name="description" content="Take a look at some of my recent work and find out how I can help you to start your own online success story." />
        
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://goddard.digital/projects/" />
                <meta property="og:title" content="Projects | Goddard Digital - Websites that deliver results" />
                <meta property="og:description" content="Take a look at some of my recent work and find out how I can help you to start your own online success story." />
                <meta property="og:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://goddard.digital/projects/" />
                <meta name="twitter:title" content="Projects | Goddard Digital - Websites that deliver results" />
                <meta name="twitter:description" content="Take a look at some of my recent work and find out how I can help you to start your own online success story." />
                <meta name="twitter:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />
            </Helmet>
            <section>
                <div style={{width: 'min(90%, 1200px)', margin: 'auto'}}>
                    <TxtImg
                        image={projectImage}
                        alt="Illustration representing an online portfolio"
                    >
                        <h2>A selection of projects to showcase recent work.</h2>
                        <p>Have a look through some recent projects to find out how other clients have been helped to achieve their goals.</p>
                    </TxtImg>
                </div>
            </section>
            <section>
                <div style={{width: 'min(90%, 1400px)', margin: 'auto'}}>
                    {projects.slice(0).reverse().map(project => (
                        <Project
                            key={project.id}
                            name={project.name}
                            href={project.url}
                            image={data.allFile.edges.filter(obj => {return obj.node.name === project.imageName})[0].node.childImageSharp.gatsbyImageData}
                            alt={project.imgAlt}
                            intro1={project.intro1}
                            intro2={project.intro2}
                        />
                    ))}
                </div>
            </section>
            <section style={{backgroundColor: 'var(--secondary-800)', position: 'relative'}}>
                <Divider>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </Divider>
                <div style={{width: 'min(90%, 1200px)', margin: 'auto', marginTop: 'var(--space-7)'}}>
                    <TxtImg
                        image={growing}
                        alt="Illustration with a graph representing growth"
                    >
                        <h2>Start your success story.</h2>
                        <p>Let's get started on building your online success story. You can schedule a call or send me a message and we'll arrange to talk through how I can help you achieve your goals.</p>
                        <Button link={true} linkTo="/contact/">Let's get started</Button>
                    </TxtImg>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        projectImage: file(relativePath: {eq: "projects.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                )
            }
        }
        growing: file(relativePath: {eq: "growing.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width:1000
                    placeholder: BLURRED
                )
            }
        }
        allFile(filter: {relativeDirectory: {eq: "projects"}}) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 1000
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`

export default Projects