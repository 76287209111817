export const projects = [{
    id: 0,
    name: 'Kibworth Osteopaths & Pilates',
    url: 'https://kibworthosteopaths.co.uk',
    imageName: 'kibworthosteopaths',
    imageAlt: 'Kibworth Osteopaths & Pilates website',
    intro1: 'Kibworth Osteopaths & Pilates were looking to establish a more marketing focused website for channeling traffic to from search engine results and a Google My Business profile.',
    intro2: 'Together, we worked on creating a site that provides its users with everything they need to know about making a booking, whilst giving the business a focus for channeling enquiries.',
}, {
    id: 1,
    name: 'Income Tax Calculator',
    url: 'https://gideongoddard.github.io/tax-calculator/',
    imageName: 'tax-calc-results',
    imageAlt: 'Income Tax Calculator website',
    intro1: 'A React app that calculates UK income tax based on annual income and country of residence (income tax in Scotland differs from the rest of the UK).',
    intro2: 'The app returns your income tax contribution based on these inputs but also provides a breakdown, explaining how much tax is paid on each portion of salary.',
}, {
    id: 2,
    name: 'Caplin Solar',
    url: 'https://caplinsolar.co.uk/',
    imageName: 'caplinsolar',
    imageAlt: 'Caplin Solar website',
    intro1: 'Caplin Solar were experiencing some reliability and performance issues with their website and were keen to rebuild and restructure it to improve these areas.',
    intro2: 'We worked to rebuild their WordPress website using the Gatsby framework, improving performance and reliability and creating a clean and lean codebase to build on.',
}, {
    id: 3,
    name: 'Paula Abbey Counselling & Psychotherapy',
    url: 'https://paulaabbey.co.uk/',
    imageName: 'paula-abbey',
    imageAlt: 'Paula Abbey Counselling & Psychotherapy website',
    intro1: 'This project was the launch of a brand new website for a new counselling & psychotherapy practice. The primary goal was to maximise enquiries from prospective clients.',
    intro2: 'We used nature and lanscape imagery to create a calming vibe, whilst also aiming to ensure that visitors to the site feel comfortable with Paula as well as confident in her expertise.',
}, {
    id: 4,
    name: 'Perfection Valet',
    url: 'https://www.perfectiondetailing.co.uk/',
    imageName: 'perfectionvalet',
    imageAlt: 'Perfection Valet website',
    intro1: 'As someone with a love for cars, I wanted to work with Richard at Perfection Valet becuase his portfolio of some of the most significant cars on the planet is unrivalled.',
    intro2: `The project was to modernise a 15 year old, non-responsive holding page to create something that can set the tone for building out a full site in future that showcases Perfection Valet's portfolio and services.`,
}, {
    id: 5,
    name: 'A Complete Demonstration of CSS Flexbox',
    url: 'https://gideongoddard.netlify.app/projects/flexbox-demo/',
    imageName: 'flexbox',
    imageAlt: 'Flexbox Demo page',
    intro1: `This project is intended as a demonstration of the range of possibilities and behaviours of flexbox. It's not supposed to serve as a guide on learning how to use flexbox - this is more about showing what it does visually.`,
    intro2: 'The demonstration breaks down the various options of (almost) every property applicable to flex containers and flex items.',
}, {
    id: 6,
    name: 'Dr. C French, Consulting Optometrist',
    url: 'https://consultingoptometrist.co.uk/',
    imageName: 'christian-french',
    imageAlt: 'Consulting Optometrist website',
    intro1: `First and foremost, this project was about migrating the website for Dr. Christian French's Optometry business to a responsive site so that the content could be properly formatted regardless of the device it was being viewed on.`,
    intro2: `Having already had some good branding work done, it was a pleasure to apply that to a modern website that can adapt and grow as the business does.`,
}]